var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "添加订单",
        visible: _vm.showDialog,
        width: "800px",
        top: "8vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "right",
                inline: "",
                "label-width": "120px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "用户:", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.form.dealer_name) + " "),
              ]),
              _c("el-form-item", { attrs: { label: "归属人:", prop: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.form.dealer_boss) +
                    " " +
                    _vm._s(_vm.form.dealer_mobile) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型1")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("现有数量")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("增加数量")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("赠送数量")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("添加端口")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v(" " + _vm._s(_vm.form.port_now || 0) + " ")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_add", $$v)
                            },
                            expression: "form.port_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_gift", $$v)
                            },
                            expression: "form.port_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_money", $$v)
                            },
                            expression: "form.port_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型2")]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _vm._v("当前到期时间"),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("续期(月)")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("赠送(月)")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("续期")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v(" " + _vm._s(_vm.form.end_time || 0) + " ")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_add", $$v)
                            },
                            expression: "form.time_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_gift", $$v)
                            },
                            expression: "form.time_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_money", $$v)
                            },
                            expression: "form.time_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型3")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("网销开关")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("现门店上限")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("升级后")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("网销升级")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: _vm.form.mall_falg,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_falg", $$v)
                            },
                            expression: "form.mall_falg",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v(" " + _vm._s(_vm.form.mall_limit_now || 0) + " ")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.mall_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_limit", $$v)
                            },
                            expression: "form.mall_limit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.mall_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_money", $$v)
                            },
                            expression: "form.mall_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型4")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训日期")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训人")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("天数")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("培训")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            placeholder: "培训日期",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.teach_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_date", $$v)
                            },
                            expression: "form.teach_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "培训人" },
                            model: {
                              value: _vm.form.teach_user,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "teach_user", $$v)
                              },
                              expression: "form.teach_user",
                            },
                          },
                          _vm._l(_vm.peixunrenList, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.username },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.teach_day,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_day", $$v)
                            },
                            expression: "form.teach_day",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.teach_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_money", $$v)
                            },
                            expression: "form.teach_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "25px", "text-align": "right" } },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }