<template>
  <el-dialog
    :modal="false"
    title="添加订单"
    :visible.sync="showDialog"
    width="800px"
    top="8vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form label-position="right" inline label-width="120px">
        <el-form-item label="用户:" prop="">
          {{ form.dealer_name }}
        </el-form-item>
        <el-form-item label="归属人:" prop="">
          {{ form.dealer_boss }} {{ form.dealer_mobile }}
        </el-form-item>
      </el-form>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型1</el-col>
          <el-col :span="5">现有数量</el-col>
          <el-col :span="5">增加数量</el-col>
          <el-col :span="5">赠送数量</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">添加端口</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              {{ form.port_now || 0 }}
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.port_add"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.port_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.port_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型2</el-col>
          <el-col :span="5">当前到期时间</el-col>
          <el-col :span="5">续期(月)</el-col>
          <el-col :span="5">赠送(月)</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">续期</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              {{ form.end_time || 0 }}
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.time_add"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.time_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.time_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型3</el-col>
          <el-col :span="5">网销开关</el-col>
          <el-col :span="5">现门店上限</el-col>
          <el-col :span="5">升级后</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">网销升级</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <!-- <el-input v-model="form.mall_falg"></el-input> -->
              <el-switch
                v-model="form.mall_falg"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </div>
          </el-col>
          <!-- 门店上限 -->
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              {{ form.mall_limit_now || 0 }}
            </div>
          </el-col>
          <!-- 升级后 -->
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.mall_limit"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.mall_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型4</el-col>
          <el-col :span="5">培训日期</el-col>
          <el-col :span="5">培训人</el-col>
          <el-col :span="5">天数</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">培训</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              <el-date-picker
                v-model="form.teach_date"
                type="date"
                placeholder="培训日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-select
                v-model="form.teach_user"
                clearable
                style="width: 100%"
                placeholder="培训人"
              >
                <el-option
                  v-for="(i, idx) in peixunrenList"
                  :key="idx"
                  :value="i.id"
                  :label="i.username"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.teach_day"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.teach_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="margin-top: 25px; text-align: right">
      <div>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import {
    guishuSelectList,
    reviewOrderDetail,
    reviewOrder,
  } from '@/api/admin/userManagement'
  // import JingxiaoshangSearch from '@/baseComponents/jingxiaoshangSearch'
  export default {
    name: 'Review',
    components: {},
    data() {
      return {
        id: 0,
        showDialog: false,
        peixunrenList: [],
        loading: false,
        form: {
          dealer_name: '',
          start_time: '',
          end_time: '0',
          account_num: '0',
          mall_limit: '0',
          dealer_id: '', // 经销商ID
          port_add: '', // 端口数
          port_gift: '', // 端口赠送数
          port_test: '', // 试用端口上限
          port_money: '', // 端口金额
          time_add: '', // 时长(月)
          time_gift: '', // 赠送时长(月)
          time_money: '', // 时长金额
          mall_falg: 1, // 网销开关1开2关
          mall_limit: '', // 网销门店上限
          mall_money: '', // 网销金额
          teach_date: '', // 培训日期 如2021-5-5
          teach_user: '', // 培训人ID
          teach_day: '', // 培训天数
          teach_money: '', // 培训金额
          bill_type: [], // 订单类型 逗号分隔 如2,3,4
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.form = {
            dealer_name: '',
            start_time: '',
            end_time: '0',
            account_num: '0',
            mall_limit: '0',
            dealer_id: '', // 经销商ID
            port_add: '', // 端口数
            port_gift: '', // 端口赠送数
            port_test: '', // 试用端口上限
            port_money: '', // 端口金额
            time_add: '', // 时长(月)
            time_gift: '', // 赠送时长(月)
            time_money: '', // 时长金额
            mall_falg: 1, // 网销开关1开2关
            mall_limit: '', // 网销门店上限
            mall_money: '', // 网销金额
            teach_date: '', // 培训日期 如2021-5-5
            teach_user: '', // 培训人ID
            teach_day: '', // 培训天数
            teach_money: '', // 培训金额
            bill_type: [], // 订单类型 逗号分隔 如2,3,4
          }
        } else {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {
      // 培训人下拉
      guishuSelectList({ pageSize: -1 }).then((res) => {
        this.peixunrenList = res.data
      })
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await reviewOrderDetail({ id: this.id })
        this.form = data
        this.loading = false
      },
      handleSubmit() {
        console.log('ad', this.form)
        let a = JSON.parse(JSON.stringify(this.form))
        a.bill_type = []
        if (a.port_add || a.port_gift || a.port_money) {
          a.bill_type.push(2)
        }
        if (a.time_add || a.time_gift || a.time_money) {
          a.bill_type.push(3)
        }
        if (a.mall_limit || a.mall_money) {
          a.bill_type.push(4)
        }
        if (a.teach_date || a.teach_user || a.teach_day || a.teach_money) {
          a.bill_type.push(5)
        }
        a.bill_type = a.bill_type.length == 0 ? '' : a.bill_type.join(',')
        a.id = this.id
        console.log('a', a)
        reviewOrder(a).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
